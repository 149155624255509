import Image from "next/image";
import React, { FC } from "react";

export const MountainsBg: FC = (): JSX.Element => {
  return (
    <div className="image-container">
      <Image
        src="/images/mountains.webp"
        alt="mountains"
        objectFit="fill"
        loading="eager"
        layout="fill"
        priority={false}
      />
    </div>
  );
};
